<template>
  <li
    class="items-center text-neutral-400 text-base"
    :class="{
      'inline-flex': !isVerticalMenu,
      'flex': isVerticalMenu
    }"
  >
    <component
      :is="to ? 'a' : 'button'"
      :href="to"
      class="relative focus:outline-none w-full h-full menu-item inline-flex items-center font-bold text-inherit hover:text-dark"
      :class="[
        paddings[padding],
        styles.arrow,
        styles.theme,
        styles.active,
        styles.hasBgWhenActive,
        innerWrapperClass
      ]"
      @click="handleClick"
      @mouseover="handleMouseover"
      @mouseleave="handleMouseleave"
    >
      <slot />

      <IconsChevron
        v-if="isDropdown"
        class="ml-2 w-4 h-4 relative top-[0.0625rem]"
        type="thin"
        :direction="active ? 'top' : 'bottom'"
      />
    </component>

    <slot name="after" />
  </li>
</template>

<script setup>
defineOptions({
  name: 'AtomsMenuItem'
})

const props = defineProps({
  arrow: {
    type: Boolean,
    default: false
  },

  theme: {
    type: String,
    default: 'default',
    validator: value => ['default', 'hover-with-border', 'hover-with-bold', 'hover-with-border-full-width', 'default-full', 'rounded', 'global-navbar'].includes(value)
  },

  to: {
    type: String,
    default: null
  },

  padding: {
    type: String,
    default: 'standard',
    validator: value => ['none', 'standard', 'small', 'large', 'xLarge', 'xxLarge', 'withImageSmall', 'largeY'].includes(value)
  },

  active: {
    type: Boolean,
    default: false
  },

  hasBackgroundWhenActive: {
    type: Boolean,
    default: true
  },

  isDropdown: {
    type: Boolean,
    default: false
  },

  isVerticalMenu: {
    type: Boolean,
    default: false
  },

  innerWrapperClass: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['click', 'mouseover', 'mouseleave'])

// variables
const paddings = ref({
  none: '',
  standard: 'py-2 px-3 xl:px-5',
  small: 'py-1 px-2 xl:px-4',
  large: 'py-3 px-3 xl:px-6',
  xLarge: 'py-4 px-3 xl:px-6',
  xxLarge: 'py-5 px-4 xl:px-6',
  withImageSmall: 'pl-1 pr-3 py-1',
  largeY: 'py-4'
})

const styles = computed(() => {
  const arrow = props.arrow
    ? 'menu-item--arrow pb-6'
    : 'hover:border-b-primary'

  const theme = (() => {
    let main = getKey(props.theme, {
      'hover-with-border': 'border-t-3 border-t-transparent border-b-3 hover:border-b-primary text-dark cursor-pointer',
      'hover-with-bold': 'border-t-3 border-t-transparent border-b-3 text-dark cursor-pointer',
      'hover-with-border-full-width': 'border-t-3 border-t-transparent border-b-3 hover:border-b-primary text-d ark cursor-pointer justify-center w-full',
      'default-full': 'w-full',
      rounded: 'rounded-full hover:font-bold'
    })

    const themesArr = ['global-navbar', 'hover-with-border', 'hover-with-border-full-width', 'rounded', 'hover-with-bold']

    if (themesArr.includes(props.theme)) {
      main += !props?.active
        ? ' font-normal lg:font-normal hover:font-bold text-dark'
        : ' lg:font-bold'
    }

    return main
  })()

  const active = (() => {
    let main = props.active
      ? 'active border-b-primary text-dark'
      : 'border-b-transparent'

    if (!props.active && props.theme === 'hover-with-bold') {
      main += ' hover:border-b-transparent'
    }

    return main
  })()

  const hasBgWhenActive = (() => {
    if (!props.hasBackgroundWhenActive) {
      return ''
    }

    let main = 'hover:bg-gray-200 active:bg-gray-200'

    if (props.active && props.hasBackgroundWhenActive) {
      main += ' bg-gray-200'
    }

    return main
  })()

  return {
    arrow,
    theme,
    active,
    hasBgWhenActive
  }
})

// functions
function handleClick (event) {
  emit('click', event)
}

function handleMouseover (event) {
  emit('mouseover', event)
}

function handleMouseleave (event) {
  emit('mouseleave', event)
}
</script>

<style
  lang="scss"
  src="./index.scss"
  scoped
>
</style>
